/* https://uigradients.com/#DayTripper  See Gradients here*/
/* @import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@100;200;300;400;500;600;700;800;900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600&family=Urbanist:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');
/* #region General Rules */
body {
    /* Ubuntu */
    /* Assistant a little skiny */
    /* Red Hat Display a little thick */
    /* Baloo 2 */
    /* Saira a little thick */
    /* Aleo */
    /* Urbanist slightly thick */
    font-family: var(--font-family-primary);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* html {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
} */

/* div {
    font-family: "Montserrat";
} */
.section-title-text {
    --title-font-size: clamp(2.05rem, 8vw, 5rem);
    font-size: var(--title-font-size);
    line-height: calc(var(--title-font-size) * 1.2);
    font-weight: 600;
    color: black;
}
.section-subtitle-text {
    font-family: var(--font-family-secondary);
    font-style: normal;
    font-weight: 600;
    font-size: clamp(1.5rem, 1.75rem, 3rem);
    line-height: 3rem;
    color: var(--transition-background-color);
}
/* border radius for popup modal in company.js currently 5px*/
:root {
    --button-radius: 5px;
    --card-radius: 5px;
    --quote-radius: 5px;
    --company-card-radius: 5px;
    --company-img-radius: 5px;
    --popup-img-radius: 5px;
    --engage-radius: 5px;
    --engage-apply-radius: 5px;
    --team-radius: 5px;
    --marquee-height: 40px;
    --homepage-horizontal-padding: 10vw;
    --homepage-vertical-padding: 10vh;
    --secondarypage-top-vertical-padding: 100px;
    --penn-branding-height: 66px;
    --font-family-primary: "Helvetica Neue", Helvetica, "Metropolis", "Arial", "sans-serif";

    /* Options: EB Garamond / Lato / Nunito Sans / Proxima Nova  */
    --font-family-secondary: "EB Garamond", "Lato", "Arial", "sans-serif";

    --subsection-title-font-size: clamp(24px, 4vw, 32px);

    --cypher-color-1: #6db0fb;
    --cypher-color-2: #152663;
    --cypher-color-3: #242b46;
    --transition-background-color: #262626;
}

/* #endregion */

/* ––––––––––––––– */

/* #region Scrollbar Styling */
.scroll-through::-webkit-scrollbar{
    height: 5px;
}
.scroll-through::-webkit-scrollbar-thumb{
    border-left: var(--homepage-horizontal-padding) solid #ededed;
    border-right: var(--homepage-horizontal-padding) solid #ededed;
}
/* Figure out how border sizing is determined on this page */
.news .scroll-through::-webkit-scrollbar-thumb, .card .scroll-through::-webkit-scrollbar-thumb {
    border-left: var(--homepage-horizontal-padding) solid #ffffff;
    border-right: var(--homepage-horizontal-padding) solid #ffffff;
}
.cypher-team .scroll-through::-webkit-scrollbar-thumb, .news .scroll-through::-webkit-scrollbar-thumb {
    background-color: var(--cypher-color-1);
}
.cypher-team .scroll-through, .news .scroll-through {
    scrollbar-color: var(--cypher-color-1) transparent !important;
    scrollbar-width: thin !important;
}
.senior-advisors .scroll-through::-webkit-scrollbar-thumb, .card .scroll-through::-webkit-scrollbar-thumb {
    background: var(--cypher-color-2);
}
.senior-advisors .scroll-through, .card .scroll-through {
    scrollbar-color: var(--cypher-color-2) transparent !important;
    scrollbar-width: thin !important;
}
.cypher-team .scroll-through::-webkit-scrollbar-thumb:hover {
    background-color: var(--cypher-color-1);
}
.senior-advisors .scroll-through::-webkit-scrollbar-thumb:hover, .card .scroll-through::-webkit-scrollbar-thumb:hover {
    background-color: var(--cypher-color-2);
}
/* #endregion */

/* #region Button Styling */
button {
    position: relative;
    border-color: var(--cypher-color-2);
    border-style: solid;

    padding: 5px;
    height: 50px;
    width: 13rem;
    font-weight: 600;
}
.black-button {
    overflow: hidden;
    line-height: calc(1.2rem + 2px);
    
    border-radius: var(--button-radius);
    -webkit-border-radius: var(--button-radius); 
    -moz-border-radius: var(--button-radius); 
    
    color: white;
    font-size: 1.2rem;

    transition: 0.5s;
    background: linear-gradient(white,white) no-repeat, black;
    background-size: 0% 100%;
    background-position:center;
}
.hero .gradient-button {
    line-height: calc(1.2rem + 2px);
    border-radius: var(--button-radius);
    -webkit-border-radius: var(--button-radius); 
    -moz-border-radius: var(--button-radius); 
    
    color: white;
    font-size: 1.2rem;

    background: linear-gradient(265.64deg, var(--cypher-color-2) 0%, var(--cypher-color-1) 100%) no-repeat, white;
    transition: 0.5s;
    background-size: 100% 100%;
    background-position:center;
    
}
.navbar .gradient-button {
    width: 100px;
    font-family: var(--font-family-primary);

    font-size: 1.2rem;
    line-height: calc(1.2rem + 2px);
    
    color: white;
    background: linear-gradient(265.64deg, var(--cypher-color-2) 0%, var(--cypher-color-1) 100%) no-repeat, white;
    border-radius: var(--button-radius);
    -webkit-border-radius: var(--button-radius); 
    -moz-border-radius: var(--button-radius); 

    transition: 0.5s;
    background-size: 100% 100%;
    background-position:center;
}



/* #endregion */

/* ––––––––––––––– */

/* #region Loading Page Section */
.loading-page {
    background-color: var(--transition-background-color);
    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 9000;
}

.background-static {
    height: 100%;
    overflow: hidden;
}

.cypher-loading {
    height: auto;
    width: clamp(100px, 12.5vw, 250px);
}
/* *::-webkit-media-controls-start-playback-button {
    display: none!important;
    -webkit-appearance: none;
  } */
/* #endregion */

/* #region Page Slider Section */
.slider-container {
    position: fixed;
    z-index: 9999;
    pointer-events: none;
    width: 100%;
}
.slider {
    width: 50%;
    height: 100vh;
    display: inline-block;
    background-color: var(--transition-background-color);
}
/* #endregion */

/* #region Navigation Bar Section */
.navbar#navbar {
    width: 100%;
    z-index: 1;
    font-family: var(--font-family-primary);
    font-weight: 500;
    font-size: 18px;
    border: transparent;
    background-color: transparent;
    transition: ease background-color 0.25s;
    /* height: 75px; */
}
.penn-header{
    height: var(--penn-branding-height);
    background-color: #242b46;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 25px 0px 25px;
}

.center-header {
    align-items: center;
    padding: 0px;
}

#navbar > div > button {
    border: 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-right: 10px;
}
#navbar > div > button > svg {
    color: white;
}
.navbar-brand {
    margin-left: 1rem !important;
}

.nav-active {
    background-color:#000 !important;
}
.nav-expand {
    background-color: rgba(0, 0, 0, 0.75) !important;
    backdrop-filter: blur(7px);
    transition: ease background-color 0.25s;
}
.navbar-links-and-buttons {
    display: flex;
    gap: 20px;
    align-items : center;
    justify-content : center;
}
.nav-link, .navbar-links-and-buttons a {
    color: white !important;
    font-family: var(--font-family-primary);
    font-weight: 600;
    padding: 0.5rem 1.5rem;
    text-decoration: none;
    text-align: center;
}
.nav-section{
    /* height: var(--penn-branding-height); */
}
.pe-3 {
    padding-left: 1rem;
}

/* #endregion */

/* #region Hero Section */
/* Container */
.hero {
    width: 100vw;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.hero .background-image {
    background-position: left;
    /* z-index: 1; */
    position: absolute;
    height: max(calc(100vh - var(--penn-branding-height)), 715px);
    width: 100%;
    /* max-width: 100vw !important; */
    background-size: cover;
    overflow: hidden;
}

/* Container Content */
.hero-main-container {
    --hero-container-height: max(calc(100vh - var(--penn-branding-height) - var(--marquee-height)), calc(715px - var(--marquee-height)));
    --hero-background-image-height: max(calc(100vh - var(--penn-branding-height)), 715px);

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: clamp(1.5rem, calc(0.045 * var(--hero-background-image-height)), 3rem);
    
    padding: calc(0.22 * var(--hero-background-image-height)) 10vw calc(0.17 * var(--hero-background-image-height)) 10vw;
    height: var(--hero-container-height);

    text-align: right;
}
.hero .section-title-text {
    -webkit-font-smoothing: antialiased;
}

.engage-buttons {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
}
.hero-description {
    margin-top: calc(-1 * (35px - 1.5rem) / 2);
    font-family: var(--font-family-secondary);
    font-size: 1.75rem;
    color: var(--cypher-color-3);
    line-height: 35px;
    font-weight: 600;
    width: 450px;
    align-self: end;
}

/* Logos */
.hero-logos {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 10vh;
    
    background-color: black;
    z-index: 1;
}
.hero-logo-image {
    max-height: 50%;
}

/* Marquee */
.marquee-section {
    width: 100%;
    
    height: var(--marquee-height);
    background-color: black;
    position: static;
    z-index: 10;
    border-top: 3px solid;
    border-bottom: 3px solid;
    -o-border-image: linear-gradient(270deg, var(--cypher-color-1) 40%, white 60%) 1;
    border-image: linear-gradient(270deg, var(--cypher-color-1) 40%, var(--cypher-color-2) 60%) 1;
}
.marquee {
    padding-top: 5px;
    padding-bottom: 5px;
    z-index: 9998;
    height: 100%;
}
.marquee-container{
    height: 100%;
}
.marquee-section .marquee .group {
    z-index: 9997;
    padding-left: 50px;
    background: linear-gradient(270deg, var(--cypher-color-1) 30%, white 70%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.1rem;
}
.marquee > * > span {
    font-weight: 700;
}
/* #endregion */

/* #region Information Section */
/* Section Container */
.information {
    /* background-color: rgb(160, 160, 160); */
    --flip-card-height: 325px;
    --flip-card-width: 275px;
    /* z-index: 9999; */
    padding-top: calc(var(--homepage-vertical-padding)*2);
    
    text-align: left;
}
.section-subtitle-text-large {
    font-size: clamp(1.75rem, 3.5vw, 5rem);
    line-height: calc(var(--title-font-size) * 1.2);
    font-weight: 600;
    color: black;
}
.section-subtitle-text-centered {
    font-family: var(--font-family-secondary);
    font-style: inormc;
    font-weight: 600;
    font-size: clamp(0.75rem, 1rem, 1.5rem);
    line-height: 4rem;
    text-align: center;
}
.section-subtitle-text-centered > a:link {
    text-decoration: none;
    opacity: 1;
}
.information-text {
    padding-left: var(--homepage-horizontal-padding);
    padding-right: calc(var(--homepage-horizontal-padding));
    /* padding-top: var(--homepage-vertical-padding); */
}

/* Card Positioning */
.bcf-cards-container {
    display: flex;
    justify-content: space-between;

    padding: 5vw var(--homepage-horizontal-padding) 5px var(--homepage-horizontal-padding);
}
.bcf-flip-card-container {
    background-color: transparent;
    width: var(--flip-card-width);
    height: var(--flip-card-height);
    perspective: 1000px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
}
.bcf-flip-card-positioner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    -webkit-transition: transform 0.8s;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    will-change: transform, box-shadow;
}

/* Card Formatting */
.bcf-flip-card-front, .bcf-flip-card-back {
    display: flex;
    flex-direction: column;
    padding: 2px;
    border: 2px solid #000000;
    border-radius: var(--card-radius);
    -webkit-border-radius: var(--card-radius); 
    -moz-border-radius: var(--card-radius); 
    line-height: 28px;
    text-align: center;
    
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.bcf-flip-card-front {
    will-change: transform, box-shadow;
    box-shadow: 5px 5px black;
    background: linear-gradient(210.95deg, var(--cypher-color-2) 27.79%, var(--cypher-color-1) 100%);
    color: #FFFFFF;
}
.bcf-flip-card-back {
    background-color: black;
    color: white;
    transform: rotateY(180deg);
    padding: 4px 0px 4px 0px;
}
.bcf-cards-container .card-title {
    padding-top: 10px;
    
    font-weight: 700;
    font-size: 1.75rem;
}
.bcf-flip-card-back .card-title {
    background: linear-gradient(270deg, var(--cypher-color-1) 30%, white 70%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.bcf-cards-container .card-text {
    padding: 10px;
    
    font-family: var(--font-family-primary);
    font-weight: 400;
    font-size: 1.25rem;
}

/* Flip Card Back Content */
.list-group {
    font-family: var(--font-family-primary);
    font-size: 1.125rem;
    line-height: 20px;
    padding: 0px;
}
.list-group-item {
    background: black;
    color: white;
    border: 2px;
    border-bottom: white;
}

/* Animations */
@-webkit-keyframes card-animation-forwards {
    0% {
        transform: translateX(0px) translateY(0px);
        -webkit-transform: translateX(0px) translateY(0px);
    }
    33% {
        transform: translateX(5px) translateY(5px);
        -webkit-transform: translateX(5px) translateY(5px);
        box-shadow: 0px 0px black;
    }
    100% {
        transform: translateX(5px) translateY(5px);
        -webkit-transform: translateX(5px) translateY(5px);
        box-shadow: 0px 0px black;
    }
}
@keyframes card-animation-forwards {
    0% {
        box-shadow: 5px 5px black;
    }
    33% {
        box-shadow: 0px 0px black;
    }
    100% {
        box-shadow: 0px 0px black;
    }
}
@-webkit-keyframes card-animation-backwards {
    0% {
        transform: translateX(5px) translateY(5px);
        -webkit-transform: translateX(5px) translateY(5px);
        box-shadow: 0px 0px black;
    }
    67% {
        transform: translateX(5px) translateY(5px);
        -webkit-transform: translateX(5px) translateY(5px);
        box-shadow: 0px 0px black;
    }
    100% {
        transform: translateX(0px) translateY(0px);
        -webkit-transform: translateX(0px) translateY(0px);
        box-shadow: 5px 5px black;
    }
}
@keyframes card-animation-backwards {
    0% {
        box-shadow: 0px 0px black;
    }
    67% {
        box-shadow: 0px 0px black;
    }
    100% {
        box-shadow: 5px 5px black;
    }
}
@-webkit-keyframes container-animation-forwards {
    0% {
        transform: translateX(0px) translateY(0px) rotateY(0deg);
        -webkit-transform: translateX(0px) translateY(0px) rotateY(0deg);
    }
    33% {
        transform: translateX(5px) translateY(5px) rotateY(0deg);
        -webkit-transform: translateX(5px) translateY(5px) rotateY(0deg);
    }
    100% {
        transform: translateX(5px) translateY(5px) rotateY(180deg);
        -webkit-transform: translateX(5px) translateY(5px) rotateY(180deg);
    }
}
@keyframes container-animation-forwards {
    0% {
        transform: translateX(0px) translateY(0px) rotateY(0deg);
    }
    33% {
        transform: translateX(5px) translateY(5px) rotateY(0deg);
    }
    100% {
        transform: translateX(5px) translateY(5px) rotateY(180deg);
    }
}
@-webkit-keyframes container-animation-backwards {
    0% {
        transform: translateX(5px) translateY(5px) rotateY(180deg);
        -webkit-transform: translateX(5px) translateY(5px) rotateY(180deg);
    }
    67% {
        transform: translateX(5px) translateY(5px) rotateY(0deg);
        -webkit-transform: translateX(5px) translateY(5px) rotateY(0deg);
    }
    100% {
        transform: translateX(0px) translateY(0px) rotateY(0deg);
        -webkit-transform: translateX(0px) translateY(0px) rotateY(0deg);
    }
}
@keyframes container-animation-backwards {
    0% {
        transform: translateX(5px) translateY(5px) rotateY(180deg);
    }
    67% {
        transform: translateX(5px) translateY(5px) rotateY(0deg);
    }
    100% {
        transform: translateX(0px) translateY(0px) rotateY(0deg);
    }
}
.is-hovered-container {
    animation-delay: 1ms;
    -webkit-animation: container-animation-forwards 1s ease forwards;
            animation: container-animation-forwards 1s ease forwards;
}
.is-hovered-card-front {
    -webkit-animation: card-animation-forwards 1s ease forwards;
            animation: card-animation-forwards 1s ease forwards;
}
.was-hovered-container {
    animation-delay: 1ms;
    -webkit-animation: container-animation-backwards 1s ease forwards;
            animation: container-animation-backwards 1s ease forwards;
}
.was-hovered-card-front {
    -webkit-animation: card-animation-backwards 1s ease forwards;
            animation: card-animation-backwards 1s ease forwards;
}

/* Swiper */
.swiper {
    width: 100%;
}
.swiper-slide {
    background-position: center;
    background-size: cover;
    width: var(--flip-card-width) !important;
    height: var(--flip-card-height) !important;
}
.swiper-wrapper {
    box-sizing: border-box !important;
}
/* #endregion */

/* #region Cohort Section */
.cohort {
    padding-top: var(--homepage-vertical-padding);
}
.cohort-text {
    padding-right: var(--homepage-horizontal-padding);
}
.flex-container {
    display: flex;
    width: 100%
}
.cohort .flex-container#cohort{
    padding-left: var(--homepage-horizontal-padding);
    padding-right: var(--homepage-horizontal-padding);
}
.card-body#quote-body {
    margin-right: 6%;
    margin-left: 6%;
    margin-top: 3%;

    padding: 1rem 1rem;
}
.flex-child#intro-right, .flex-child#card-right  {
    margin-left: auto; 
    margin-right: auto;
    text-align: right; 
}
.flex-child#intro-right {
    width: 100%;
}
.flex-child#card-right {
    align-self: flex-end;
    width: 60%;
    margin-right: 0;  
}
.flex-child#card-right > .card-title {
    font-size: 1.5vw;
}
.flex-child#card-right > .card-subtitle {
    font-size: 1.25vw;
}
p#subtitle-top {
    font-size: 1vw;
}
h1#subtitle-bottom {
    font-size: 6vw;
}
#quote-card {
    width: 35%;
    margin-top: 10%;
    display: grid;
    grid-template: 1fr / 1fr;
}
#quote-card > * {
    grid-column: 1 / 1;
    grid-row: 1 / 1;
}
.animation-helper {
    height: 100%;
    width: 100%;
    position: relative;
}
.background-box {
    position: absolute;
    height: 57.5%;
    width: 100%;
    margin-left: 10px;
    margin-top: 10px;
    border-radius: var(--quote-radius);
    -webkit-border-radius: var(--quote-radius); 
    -moz-border-radius: var(--quote-radius);
    background: linear-gradient(71.24deg, var(--cypher-color-1) 0%, var(--cypher-color-2) 98.86%);;
    z-index: 1;
}
#quote-card > div > div> .card {
    height: 57.5%;
    width: 100%;
    border: 2px solid black;
    z-index: 2;
}
.card-text#quote-text {
    margin-top: 6%;
    font-size: 1.35vw;
}
.flex-child#card-left > img{
    width: 7vw;
}
#quote {
    border-radius: var(--quote-radius);
    -webkit-border-radius: var(--quote-radius); 
    -moz-border-radius: var(--quote-radius);
    transition: transform 0.5s;
    display: inline-block;
    text-align: center;
}
.cohort .vstack:last-of-type {
    margin-right: 5px;
}
.cohort .col:last-of-type > div > div {
    margin-right: 5px;
}
.cohort .col > div {
    display: flex;
    justify-content: center;
}

/* Map */
.map {
    text-align: center;
    padding-left: var(--homepage-horizontal-padding);
    padding-right: var(--homepage-horizontal-padding);
}
@-webkit-keyframes frontTransition {
from {
    r: 6;
    fill: var(--cypher-color-1);
}
to {
    r: 10;
    fill: #000000;
}

}
@keyframes frontTransition {
from {
    r: 6;
    fill: var(--cypher-color-1);
}
to {
    r: 10;
    fill: #000000;
}

}


/* Company Grid */
.company {
    margin-top: 25px;
}
.company-container {
    display: flex;
    justify-content: center;
}
.cohort-grid {
    margin-top: -10%;
}
.scroll-through .vstack {
    padding-bottom: 15px;
    flex: none !important;
}

/* Company Cards */
.company-card {
    width: 255px;
    height: auto;
    border: 2px solid black;
    border-radius: var(--company-card-radius);
    -webkit-border-radius: var(--company-card-radius); 
    -moz-border-radius: var(--company-card-radius);
    box-shadow: 5px 5px;
}

.flex-child#company-logo > img{
    width:65px;
    background-color: black;
    border-radius: var(--company-img-radius);
    -webkit-border-radius: var(--company-img-radius); 
    -moz-border-radius: var(--company-img-radius);
}
#company-info {
    margin-left: 10px;
}
.helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}
.flex-child#company-logo {
    width: 30%;
    vertical-align: middle;
}
.flex-child#company-info .card-title{
    text-align: left;
    font-size: 16.5px; /* Card should not resize*/
}
#card-info {
    width: 150px;
}
#tags {
    width: auto;
    float: left;
}
/* #company-links {
    width: 100%;
}
#company-links > div {
    float: right;
}
#company-links > div > a > svg{
    color: black; 
} */
.cohort .scroll-through {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}


/* Popup Company Description */
body > div.fade.modal.show > div > div {
    background-color: transparent;
    border: transparent;
}
.flex-child#popup-modal {
    width: 60%;
}
.model-title {
    padding-right: 10%;
}
#popup-modal > div.modal-header > div:nth-child(2) {
    margin-left: 10px;
}
.flex-child#popup-img {
    width: 40%;
    vertical-align: middle;
}
.flex-child#popup-img > img{
    width: 90%;
    margin: 5%;
    border-radius: var(--popup-img-radius);
    -webkit-border-radius: var(--popup-img-radius); 
    -moz-border-radius: var(--popup-img-radius);
    background-color: black;
}
#popup-modal > div.modal-footer > a {
    color: black;
}
#popup-modal > div.modal-body > p {
    font-size: 14px;
}
/* #endregion */

/* #region Engage Section */
/* Container */
.engage {
    --engage-card-border-size: 2px;

    --engage-button-width: clamp(200px, 18vw, 300px);
    --engage-button-height: 50px;
    --engage-button-font-size: clamp(18px, 1.0vw, 25px);
    --engage-button-border-size: 2px;
    --engage-button-box-shadow-size: 5px;

    padding-top: var(--homepage-vertical-padding);
}
.engage-cap {
    display: flex;
    justify-content: center;
    
    position: relative;
    gap: 5rem;
}
.engage-cap::before {
    display: block;

    position: absolute;
    z-index: 0;
    left: 0;
    top: 50%;
    height: 55%;
    width: 100%;

    content: "";
    background: #ededed;
    border-top: 2px solid black;
}

/* General Card Rules */
.engage .card-body {
    display: flex;
    flex-direction: column;

    padding: 2rem 2rem calc(2rem + 5px) 2rem;

    text-align: center;
}
.engage .card-title {
    font-size: clamp(2.25rem, 2.5vw, 2.75rem);
    font-weight: 600;
}
.engage .card-text {
    padding: 1rem 10%;
    
    font-family: var(--font-family-primary);
    font-size: clamp(1rem, 1.5vw, 1.75rem);
    line-height: 28px;
    color: white;
}

/* Apply Specific Card Rules */
.apply-background {
    position: relative;
    padding: var(--engage-card-border-size);

    border-radius: var(--engage-radius);
    -webkit-border-radius: var(--engage-radius); 
    -moz-border-radius: var(--engage-radius);
    transition: background-position 1.5s;
    /* background-size: 200% 200%; */
    background-size: 250% 250%;
    
    background-image: linear-gradient(to right, var(--cypher-color-1) 0%, var(--cypher-color-2) 38%,  var(--cypher-color-2) 50%, #000000 50%, #000000 100%);
}
.engage .apply.card {
    width: 35vw;
    height: 100%;
    
    border-radius: var(--engage-apply-radius);
    -webkit-border-radius: var(--engage-apply-radius); 
    -moz-border-radius: var(--engage-apply-radius);
    background-color: black;
    color: white;
}
.apply-button-container {
    position: relative;
    align-self: center;

    height: var(--engage-button-height);
    width: var(--engage-button-width);
    margin: auto auto 0px auto;
}
.white-button {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    height: 100%;
    width: var(--engage-button-width);

    color: black;
    background: #FFFFFF;
    border: var(--engage-button-border-size) solid #222222;
    border-radius: var(--button-radius);
    box-shadow: var(--engage-button-box-shadow-size) var(--engage-button-box-shadow-size) var(--box-shadow-color);
    
    font-size: var(--engage-button-font-size);
}
.apply-button-background {
    background-image: linear-gradient(to right, var(--cypher-color-1) 0%, var(--cypher-color-1) 50%, black 50%, black 100%);
    background-size: 200% 100%;
    position: absolute;
    top: var(--engage-button-box-shadow-size);
    left: var(--engage-button-box-shadow-size);
    height: 100%;
    width: var(--engage-button-width);
    border-radius: var(--button-radius);

    transition: background-position 1.5s;
}

/* Join Us Specific Card Rules */
.engage .join-us.card {
    width: 35vw;
    padding: 2px;
    
    background: linear-gradient(to top right, var(--cypher-color-2) 10%, var(--cypher-color-1) 90%);
    border: var(--engage-card-border-size) solid #000000;
    border-radius: var(--engage-radius);
    -webkit-border-radius: var(--engage-radius); 
    -moz-border-radius: var(--engage-radius);
    transition: 1.5s;
    background-size: 250% 250%;
    color: white;
}
.engage .join-us .gradient-button {
    position: relative;
    background-size: 250% 110%;
    height: var(--engage-button-height);
    width: var(--engage-button-width);
    
    background-image: linear-gradient(to right, var(--cypher-color-1) 1.29%, var(--cypher-color-2) 50%, white 50%, white 100%);
    border: var(--engage-button-border-size) solid #000000;
    border-radius: var(--button-radius);
    box-shadow: var(--engage-button-box-shadow-size) var(--engage-button-box-shadow-size) black;

    font-size: var(--engage-button-font-size);

    transition: background-position 1.5s;
}
.engage .join-us a {
    margin-top: auto;
}
.engage .button-container {
    width: var(--engage-button-width);
    align-self: center;
}
/* #endregion */

/* #region Team Section */
/* Container */
.team {
    padding: var(--homepage-vertical-padding) 0 var(--homepage-vertical-padding) 0;
    
    text-align: left;
    background-color: #ededed;
}

/* Section Title Rules */
.team-text {
    padding-left: var(--homepage-horizontal-padding);
    padding-right: var(--homepage-horizontal-padding);
}
.team .subsection-title-text {
    padding-right: var(--homepage-horizontal-padding);
    
    background-size: 200% 100%;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
    text-align: right;
    font-weight: 700;
    font-size: 2rem;
    line-height: 5rem;

    transition: ease 2s 1s;
}

/* Scroll Through */
.scroll-through {
    min-width: 100%;
    overflow-x: scroll !important;
    overflow-y: hidden;
    padding-left: var(--homepage-horizontal-padding);
    padding-right: var(--homepage-horizontal-padding);
    gap: 50px;
 
    margin-top: 2vh;
    margin-bottom: 5vh;

    display: flex;
    justify-content: space-between;
}
.senior-advisors .scroll-through {
    flex-direction: row-reverse;
    margin-bottom: 2vh;
}
.scroll-cushion {
    height: 20px;
}

/* General Card Rules */
.team .card {
    width: var(--team-member-card-width);
    height: clamp(12.5rem, 15vw, 17.185rem);
    padding: 2%;

    border-radius: var(--team-radius);
    -webkit-border-radius: var(--team-radius); 
    -moz-border-radius: var(--team-radius);
    font-family: var(--font-family-primary);

    transition: 0.5s ease;
}
.team .member-card-container {
    --team-member-card-width: clamp(12.5rem, 15vw, 17.185rem);
    width: var(--team-member-card-width);
}
.team .card-body {
    display: flex;
    flex-direction: column;
    flex: 1;

    text-align: left;
}
.team .card-title {
    font-size: clamp(1.125rem, 1.25vw, 1.5rem);
    font-weight: 700;
}
.team .card-subtitle {
    font-size: clamp(0.8125rem, 0.9vw, 1.0625rem);
    font-weight: 400;
}
.team .card-img {
    display: block;
    width: clamp(18px, 45%, 85px);
    height: clamp(18px, 45%, 85px);
    margin-bottom: 7px;

    border: solid 2px #F3F4F5;
    background-color: #F3F4F5;
    border-radius: 50%;
    padding: 1px;
    -webkit-border-radius: 50%; 
    -moz-border-radius: 50%;
}
.team .card-icons {
    display: flex;
    justify-content: space-evenly;
    
    margin-top: auto;
}

/* Cypher-Specific Card Rules */
.cypher-team .card { 
    border: 2px solid black;
    box-shadow: 5px 5px var(--cypher-color-1);
    background-color: white;
    color: black;
}
.cypher-team .card-subtitle {
    color:rgba(0, 0, 0, 0.8);
}
.cypher-team .card-img {
    border: solid 2px rgb(133,133,133);
    background-color: rgb(133,133,133);
}
.team #khushi_shelat {
    margin-right: 5px;
}

/* Advisor-Specific Card Rules */
.senior-advisors .card {
    border: 2px solid white;
    box-shadow: 5px 5px var(--cypher-color-2);
    background-color: black;
    color: white;
}
.senior-advisors .card-subtitle {
    color:rgba(256, 256, 256, 0.8);
}
.team #jeff_amico {
    margin-right: 5px;
}
/* #endregion */

/* #region Corporate Partners Section */
.partners {
    padding-top: clamp(15px, 15vw, 80px);
    padding-bottom: clamp(10px, 10vw, 60px);
    
    background: #111111;
}
.partners .subsection-title-text {
    margin-bottom: 25px;
    font-weight: 600;
    font-size: clamp(1.5rem, 4vw, 2.5rem); 
    /* font-size: 3.5rem; */
    /* background: linear-gradient(270deg, var(--cypher-color-1) 40%, white 60%); */
    background: white;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
    text-align: center;
}
.partners .container {
    padding-top: clamp(10px, 1vw, 20px);
    padding-bottom: clamp(10px, 1vw, 20px);
}
div.partners > div.container {
    width: 100vw;
}
.partner-logo {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    height: auto;
    max-width: 175px;
    /* min-width: 60px; */
}

/* #endregion */

/* #region Projects Section */

.projects {
    padding-top: var(--homepage-vertical-padding);
    padding-bottom: var(--homepage-vertical-padding);
}
.projects-text {
    padding-right: var(--homepage-horizontal-padding);
}
.flex-container {
    display: flex;
    width: 100%
}
.projects .subsection-title-text {
    padding-top: 2vh;
    padding-right: var(--homepage-horizontal-padding);
    padding-left: var(--homepage-horizontal-padding);
    
    text-align: center;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
}
.projects .description {
    padding-top: 2vh;
    padding-right: var(--homepage-horizontal-padding);
    padding-left: var(--homepage-horizontal-padding);
    
    text-align: center;
    /* font-weight: 700; */
    font-size: 1.25rem;
    line-height: 1.5rem;
}

/* Map */
.map {
    text-align: center;
    padding-left: var(--homepage-horizontal-padding);
    padding-right: var(--homepage-horizontal-padding);
    padding-top: 2vh;
    padding-bottom: 2vh;
}
.map img {
    max-width: 60%;
    height: auto; 

    border: 2px solid white;
    box-shadow: 5px 5px var(--cypher-color-1);
    color: white;

    border-radius: var(--engage-apply-radius);
    -webkit-border-radius: var(--engage-apply-radius); 
    -moz-border-radius: var(--engage-apply-radius);
    color: black;
}
.projects .engage-buttons {
    padding-top: 4vh;
    display: flex;
    gap: 20px;
    justify-content: center;
}

/* #endregion */

/* #region Footer Section */
.footer {
    padding: 2em 2em;
    
    background-color: #242B46; 
    /* #222222 */
    color: white;
}
.footer > * {
    font-size: 1rem;
    font-weight: 500;
}

.footer-left {
    display: flex;
    align-items: center;
    text-align: left;
    width: 50rem;
    padding-right: 1rem;
}

.footer-left-text{
    margin-bottom: 0;
}
.footer-middle {
    width: fit-content;
    padding-right: 1.5rem;
}
.footer-middle-left {
    width: fit-content;
    padding-right: 1.5rem;
}
.footer-subtitle {
    font-weight: 700;
}
.footer-links{
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 1rem;
    font-weight: 500;
}

.footer-left-text > a:link {
    text-decoration: none;
    color: white;
    opacity: 1;
}

.footer-right > div.hstack.gap-1 > a:link,
.footer-links a:link {
    text-decoration: none;
    color: white;
    opacity: .7;
}

.footer-left-text > a:visited,
.footer-right > div.hstack.gap-1 > a:visited,
.footer-links a:visited {
    text-decoration: none;
    color: white;
}


.footer-left-text > a:active,
.footer-right > div.hstack.gap-1 > a:active
.footer-links a:active {
    text-decoration: none;
    color: white;
}

.footer-wharton-logo{
    width: 50%;
    padding-bottom: 1rem;
    /* padding-top: 1rem; */
    margin-left: 2rem;
}

.footer-penn-logo {
    width: 40%;
    padding-bottom: 1rem;
    /* padding-top: 1rem; */
}


/* #root > div > div.footer > div > div.footer-stack > div.flex-child.footer-right > div.hstack.gap-1 > a > svg */

.footer-right .hstack a svg {
    border-radius: 10px;
}
.footer-subtitle{
    text-align: left;
}
.footer-right > .hstack > a {
    color: white;
}

.flex-child.footer-right {
    width: fit-content;
}
.flex-child.footer-right .hstack{
    justify-content: left;
    width: 100%;
}
.footer-stack {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: right;
}

.footer-stack-container {
    display: flex;
    flex-direction: row;
    width: fit-content;
    justify-content: right;
}
/* #endregion */

/* #region TeamPage Section */
/* Header */
.secondary-page-header {
    width: 100%;
    padding-top: var(--secondarypage-top-vertical-padding);
    display: inline-flex;
    justify-content: flex-end;
    gap: 15px;
    padding-bottom: 2vh;
}
.secondary-page-header > * {
    margin-top: auto;
}
.secondary-page-header > img {
    width: 4rem;
}
.secondary-page-header .section-title-text {
    font-size: 3.5rem;

    line-height: normal;
    margin-bottom: 0;
}

/* Content */
.secondary-page-wrapper {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
}

/* Quote Carousel */
.team-page .carousel {
    --carousel-text-padding: 40px;
}
.team-page .carousel .card {
    background-color: black;
}
.team-page .carousel .card-body {
    display: flex;
    font-family: var(--font-family-primary);
    text-align: right;
    justify-content: space-between;
    background-color: transparent;

    color: white;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0px !important;
    height: 450px;
    border: 5px solid black;
    border-radius: 8px;
    -webkit-border-radius: 8px; 
    -moz-border-radius: 8px; 
}
.team-page .carousel .card-img {
    max-width: 585px;
    max-height: 520px;
    width: 32.5%;
    align-self: flex-end;
}
.team-page .carousel .carousel-text-container {
    display: flex;
    flex-direction: column;
    padding: var(--carousel-text-padding) var(--carousel-text-padding) var(--carousel-text-padding) 0px;
    width: 70%;
}
.team-page .carousel .carousel-bottom-container {
    display: flex;
    justify-content: space-between;
}
.team-page .carousel .card-text {
    --quote-size-helper: min(1400px, 100vw);
    font-weight: 900;
    font-size: max(calc(0.0275 * var(--quote-size-helper)), 2.25rem);
    line-height: normal;
}
.team-page .carousel .card-title {
    margin-top: auto;
    font-size: 2rem;
    font-weight: 900;
}
.team-page .carousel .card-subtitle {
    font-size: 1.5rem;
    font-weight: 300;
}
.carousel .carousel-control-prev, .carousel .carousel-control-next {
    width: 30px;
    -webkit-filter: none;
            filter: none;
    opacity: 1;
}
.team-page .carousel-inner {
    border-radius: 8px;
    -webkit-border-radius: 8px; 
    -moz-border-radius: 8px; 
}

/* Member Cards */
.team-page .team-array {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-column-gap: 25px;
    grid-row-gap: 25px;
}
.team-page .member-card-information {
    display: flex;
    padding: 10px;
}
.team-page .member-text-container {
    width: 87%;
}
.team-page .card-icons {
    display: flex;
    flex-direction: column;
    justify-content: center;

    gap: 0.5em;
    margin-left: auto;
}
.team-page .hstack {
    flex-wrap: wrap;
    justify-content: center;

    width: 100%;
    gap: 0.5em;
    padding: 0.1em;
}
.team-page .card {
    font-family: var(--font-family-primary);
    border: none;
}
.team-page .team-array .card-body {
    padding: 5px;

    background-color: black;
    border-radius: 10px;
    -webkit-border-radius: 10px; 
    -moz-border-radius: 10px; 
    box-shadow: 0 1px 1px rgba(0,0,0,0.12), 
                0 2px 2px rgba(0,0,0,0.12), 
                0 4px 4px rgba(0,0,0,0.12), 
                0 8px 8px rgba(0,0,0,0.12),
                0 16px 16px rgba(0,0,0,0.12);

    color: white;
    text-align: left;
}
.team-page .team-array .card-title {
    /* font-size: 1.625rem; */
    font-size: 1.375rem;
    font-weight: 900;
}
.team-page .team-array .card-subtitle {
    height: 2em;
    
    font-size: 0.95rem;
    font-weight: 400;
}
.team-page .card-img {
    width: calc(100%);
    border-radius: 8px;
    -webkit-border-radius: 8px; 
    -moz-border-radius: 8px; 
}
.team-page .scroll-cushion {
    height: 0;
}
.team-array .hstack {
    height: 3.5rem;
}

/* Interactive Panel */
.interact-panel {
    padding: 2rem 4rem 2rem 4rem;
    margin: 3rem 0vw 3rem 0vw;
    width: 100%;
    border-radius: 1rem;
    -webkit-border-radius: 1rem; 
    -moz-border-radius: 1rem; 
    background-color: #EDEDED;
    box-shadow: 0 1px 1px rgba(0,0,0,0.25), 
              0 2px 2px rgba(0,0,0,0.25), 
              0 4px 4px rgba(0,0,0,0.25), 
              0 8px 8px rgba(0,0,0,0.25),
              0 16px 16px rgba(0,0,0,0.25);
}
.interact-section-text {
    font-weight: 700;
    font-size: 2.5rem;
    color: black;
}
.interact-flex-group {
    display: flex;
    justify-content: space-between;
    align-content: center;
}

/* Radio Buttons */
.team-radio-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    -moz-column-gap: 2rem;
         column-gap: 2rem;

    color: black;
    font-weight: bolder;
    font-size: 1.15rem;
}
.team-radio {
    display: inline-flex;
    align-items: center;

    cursor: pointer;
}
.team-radio-input {
    display: none;
}
.team-radio-button {
    box-sizing: border-box;
    
    width: 1.25em;
    height: 1.25em;
    
    margin-right: 10px;
    padding: 4px;

    border: 2px solid black;
    border-radius: 50%;
    -webkit-border-radius: 50%; 
    -moz-border-radius: 50%; 
}
.team-radio-button::after {
    display: block;
    content: "";

    width: 100%;
    height: 100%;
    
    background: var(--cypher-color-1);
    border-radius: 50%;
    -webkit-border-radius: 50%; 
    -moz-border-radius: 50%; 

    transform: scale(0);
    transition: transform 0.4s cubic-bezier(.45,2,.5,.75);
}
.team-radio-input:checked + .team-radio-button::after {
    transform: scale(1);
}

/* Search Bar */
.team-search-container {
    display: inline-flex;
    align-items: center;

    position: relative;
    height: 100%;
    margin-left: auto;

    border: 1px solid black;
    border-radius: 50rem;
    -webkit-border-radius: 50rem; 
    -moz-border-radius: 50rem; 
}
.team-search-container > img {
    position: absolute;
    width: 1.25rem;
    left: 0.75rem;
}
.team-search-bar {
    z-index: 1;
    
    width: 100%;
    padding: 0.5rem 1rem 0.5rem 2.5rem;

    background-color: transparent;
    border: none;

    font-size: 1rem;
    font-weight: 300;
}
.team-search-bar:focus {
    outline: none;
}

/* Mentor Checkboxes */
.expertise-pill-checkbox + label {
    padding: 0em .65em;

    color: black;
    font-size: 1em;
    font-weight: 700;

    border: 2px solid #ededed;
    border-radius: 50rem;
    opacity: 0.75;

    cursor: pointer;

    transition: 0.1s border ease;
}
.expertise-pill-checkbox:checked + label {
    border: 2px solid black;
    opacity: 1;
}
input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
         appearance: none;
}

/* Search Output */
.search-output {
    margin-bottom: 75px;
}

/* No Search Output */
.no-search-output {
    --no-search-output-margin: 30px;
}
.no-search-output hr {
    margin: var(--no-search-output-margin) 0px var(--no-search-output-margin) 0px;
}
.no-search-output hr.top-bar {
    margin-top: calc(var(--no-search-output-margin) + 3rem)
}
.no-search-output svg {
    color: #6f6f6f;
    width: clamp(40px, 4vw, 55px);
    height: clamp(40px, 4vw, 55px);
    margin-bottom: 0.5;
}
.no-search-output .no-search-output-text {
    color: #6f6f6f;
    font-weight: 500;
    font-size: 1rem;
}
/* #endregion */

/* #region NewsPage Section */
.news-header {
    width: 80vw;
    max-width: 1400px;
}
.news-description {
    margin-left: 10vw;
    margin-right: 10vw;
}
.news-header-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.news .news-description {
    padding-right: var(--homepage-horizontal-padding);
    padding-left: var(--homepage-horizontal-padding);
}
.news .team-radio-group {
    column-gap: 1.5rem;
}

.news .highlight-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4vh;
    margin-bottom: 4vh;
    width: 100%;
    text-align: left;
    padding-left: var(--homepage-horizontal-padding);
    padding-right: var(--homepage-horizontal-padding);
    padding-bottom: 4vh
}

.news .right-side {
    max-width: 50%;
    padding-left: 2vh;
}

.news .upper-text {
    padding-bottom: 2vh;
}

/* Person/news Description */

.news-description, 
.person-description {
    margin: 2.5rem auto 2.5rem auto;

    font-size: 1.75rem;
    font-weight: 600;
    font-family: var(--font-family-secondary);

}

#recentArticle, 
#recentPaper,
#recentPodcast {
    border-color: rgb(255, 255, 255);
    min-height: 200px;
    max-height: 300px;
    background-size: 1200px;
    background-color: transparent;
    background: url("../assets/images/backgrounds/news\ background.png") top left no-repeat;
}

#recentArticle:hover,
#recentPaper:hover,
#recentPodcast:hover {
    cursor: pointer;
    
}

/* #recentArticle {
    background: url("../assets/images/backgrounds/news\ background.png") top left no-repeat;
    background-size: 1200px;
    background-color: transparent;
}


#recentPaper {
    background: url("../assets/images/backgrounds/news\ background.png") top left no-repeat;
    background-size: 1200px;
    background-color: transparent;
}

#recentPodcast {
    background: url("../assets/images/backgrounds/news\ background.png") top left no-repeat;
    background-size: 1200px;
    background-color: transparent;
} */

#recentArticle > * > .card-title,
#recentPaper > * > .card-title,
#recentPodcast > * > .card-title {
    font-size: min(50px, 2.5vw);
    font-weight: 600;
    font-family: var(--font-family-primary);
    padding-top: 50px;
}


#recentArticle > * > .card-subtitle {
    font-size: min(30px, 1.5vw);
}

#recentArticle > * > .card-title,
#recentArticle > * > .card-subtitle,
#recentPaper > * > .card-title,
#recentPaper > * > .card-subtitle,
#recentPodcast > * > .card-title,
#recentPodcast > * > .card-subtitle {
    text-align: left;
    color: white;
    padding-right: 50px;
    padding-left: 40px;
    padding-bottom: 10px;
}

.card#articles .card-body .hstack, 
.card#papers .card-body .hstack, 
.card#visuals .card-body .hstack {
   position: absolute;
   bottom: 15px;
}

.card#recentPaper .card-body .hstack, 
.card#recentArticle .card-body .hstack,
.card#recentPodcast .card-body .hstack {
   position: absolute;
    padding-left: 40px;
    /* bottom: 25px; */
}

#recentPaper .card-body .hstack,
#recentArticle .card-body .hstack, 
#recentPodcast .card-body .hstack {
    flex-wrap: wrap;
    margin-right: 30px;
}


.carousel-control-next,
.carousel-control-prev  {
    color: black;
    opacity: 1;
}


.carousel-control-next:focus, 
.carousel-control-next:hover, 
.carousel-control-prev:focus, 
.carousel-control-prev:hover {
    color: black;
}

.carousel-indicators button {
    width: 0.4rem !important;
    height: 0.4rem !important;
    border-radius: 50%;
    -webkit-border-radius: 50%; 
    -moz-border-radius: 50%; 
}
  
.carousel-indicators button:not(:first-child) {
    margin-left: 0.5rem;

}

.carousel-indicators .active {
    background-color: #00A6FC;
}

#articles,
#papers,
#visuals {
    height: 400px;
    width: 290px;
    margin-bottom: 30px;
    background-color: #000;
    border-width: 0px;
    border-color: #000;
    border-radius: 10px;
    -webkit-border-radius: 10px; 
    -moz-border-radius: 10px; 
    box-shadow: 0 1px 1px rgba(0,0,0,0.12), 
    0 2px 2px rgba(0,0,0,0.12), 
    0 4px 4px rgba(0,0,0,0.12), 
    0 8px 8px rgba(0,0,0,0.12),
    0 16px 16px rgba(0,0,0,0.12);
}

.card#articles img{
    border-radius: 8px;
    -webkit-border-radius: 8px; 
    -moz-border-radius: 8px; 
}

.card#articles .card-body .card-title, 
.card#articles .card-body .card-subtitle{
    color: white;
    text-align: left;
    margin-top: 20px;
}

.card#articles .card-body .card-title {
    font-weight: 600;
    font-size: 25px;
}

#papers .card-body .card-title, #visuals .card-body .card-title {
    text-align: left;
    font-family: var(--font-family-primary);
    font-size: 25px;
    font-weight: 600;
    color: white;
    margin-top: 20px;
}

.default-no-news {
    width: 80vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-weight: 700;
    display: flex;
    justify-content: center;
}

.research-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.news-checkboxes {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 2vh;
    padding-bottom: 2vh;
    border-radius: 5px;
    -webkit-border-radius: 5px; 
    -moz-border-radius: 5px; 
    max-width: 1400px;

    --test-var: 100%;

    width: var(--test-var);
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: #EDEDED;
    box-shadow: 0 1px 1px rgba(0,0,0,0.25), 
              0 2px 2px rgba(0,0,0,0.25), 
              0 4px 4px rgba(0,0,0,0.25), 
              0 8px 8px rgba(0,0,0,0.25),
              0 16px 16px rgba(0,0,0,0.25);
    
}

.news-checkboxes > div > * > label {
    font-size: .75rem ;
}

.news-checkboxes-containers {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4vh;
    margin-bottom: 4vh;
    width: 100%;
    /* max-width: 1400px; */
}

.news-checkbox-stacks {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

.radio-news-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.news .no-search-output {
    width: 100%;
    padding-right: var(--homepage-horizontal-padding);
    padding-left: var(--homepage-horizontal-padding);
}

.news .no-search-output hr.bottom-bar {
    margin-bottom: calc(var(--no-search-output-margin) + 3rem);;
}

.vl {
    margin-left: 10px;
    margin-right: 10px;
    height: 50px;
    border-radius: 10px;
    -webkit-border-radius: 10px; 
    -moz-border-radius: 10px; 
    border-left: 1px solid rgb(113, 113, 113);
}

.type-specific-stack{
    flex-wrap: wrap;
}

.panel-description {
    font-family: var(--font-family-secondary);
    text-align: center;
    padding-bottom: 0.75em;
    font-weight: 600;
    font-size: 1.75rem;
}

#news-section-articles > div > div.no-search-output {
    width: 80vw;
}

/* Radio Buttons */
.news-radio-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    -moz-column-gap: 2rem;
         column-gap: 2rem;

    color: black;
    font-weight: bolder;
    font-size: 1.25rem;
}
.news-radio {
    display: inline-flex;
    align-items: center;

    cursor: pointer;
}
.news-radio-input {
    display: none;
}
.news-radio-button {
    box-sizing: border-box;
    
    width: 1.75em;
    height: 1.75em;
    
    margin-right: 10px;
    padding: 4px;

    border: 2px solid black;
    border-radius: 50%;
    -webkit-border-radius: 50%; 
    -moz-border-radius: 50%; 
}
.news-radio-button::after {
    display: block;
    content: "";

    width: 100%;
    height: 100%;
    
    background: var(--cypher-color-1);
    border-radius: 50%;
    -webkit-border-radius: 50%; 
    -moz-border-radius: 50%; 

    transform: scale(0);
    transition: transform 0.4s cubic-bezier(.45,2,.5,.75);
}
.news-radio-input:checked + .news-radio-button::after {
    transform: scale(1);
}

.articles {
    padding-left: var(--homepage-horizontal-padding);
    padding-right: var(--homepage-horizontal-padding);
}


.twitter {
    background-color: #111111;
    padding-bottom: 3rem;
}
.twitter .subsection-title-text{
    text-align: center;
    padding-right: 0;
    padding-top: 6vh;
    margin-bottom: 25px;
    font-weight: 700;
    /* font-size: 32px; */
    font-size: clamp(1rem, 6vw, 2rem); 
    /* background: linear-gradient(270deg, var(--cypher-color-1) 40%, var(--cypher-color-2) 60%); */
    background: white;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
}

.twitter-tweet {
    width: 500px !important;
    padding-left: 1rem;
    padding-right: 1rem;
}
/* .centerContent {
    width: 500ppx;
} */


/* #endregion */

/* #region Companies.Page Section */
/* Company Cards */
.companies-page .companies-array {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-column-gap: 25px;
    grid-row-gap: 25px;
}
.companies-page .companies-card-information {
    display: flex;
    padding: 10px;
}
.companies-page .companies-text-container {
    width: 100%;
}
.companies-page .card-icons {
    display: flex;
    flex-direction: column;
    justify-content: center;

    gap: 0.5em;
    margin-left: auto;
}
.companies-page .hstack {
    flex-wrap: wrap;
    justify-content: center;

    width: 100%;
    gap: 0.5em;
    padding: 0.1em;
}
.companies-page .card {
    font-family: var(--font-family-primary);
    border: none;
}
.companies-page .companies-array .card-body {
    padding: 5px;

    background-color: black;
    border-radius: 10px;
    -webkit-border-radius: 10px; 
    -moz-border-radius: 10px; 
    box-shadow: 0 1px 1px rgba(0,0,0,0.12), 
                0 2px 2px rgba(0,0,0,0.12), 
                0 4px 4px rgba(0,0,0,0.12), 
                0 8px 8px rgba(0,0,0,0.12),
                0 16px 16px rgba(0,0,0,0.12);

    color: white;
    text-align: left;
}
.companies-page .companies-array .card-title {
    font-size: 1.375rem;
    font-weight: 900;
}
.companies-page .companies-array .card-subtitle {
    height: 2em;
    
    font-size: 0.95rem;
    font-weight: 400;
}
.companies-page .card-img {
    width: calc(100%);
    border-radius: 8px;
    -webkit-border-radius: 8px; 
    -moz-border-radius: 8px; 
}
.companies-page .scroll-cushion {
    height: 0;
}
.companies-array .hstack {
    height: 3rem;
}

/* Interaction Panel */
.companies-page .panel-description {
    line-height: normal;
}
.companies-page .interact-panel {
    margin: 1rem 0vw 3rem 0vw;
}
/* #endregion */

/* #region Screen Splits */
@media screen and (min-width: 1800px) {
    #recentArticle, 
    #recentPaper,
    #recentPodcast {
        margin-left: 43px;
        margin-right: 43px;
    }
}
@media screen and (min-width: 1600px) {
    .team .card-body{
        gap: 2px;
    }
}
@media screen and (min-width: 1500px) {
    .bcf-flip-card-container {
        --bcf-grow-flip-container: clamp(250px, 20vw, 300px);
        width: var(--bcf-grow-flip-container) !important;
        height: calc(1.2 * var(--bcf-grow-flip-container) + 5px) !important;
    }
    /* .bcf-flip-card-front, .bcf-flip-card-back {
        line-height: calc(0.08 * var(--bcf-grow-flip-container));
    } */
    .bcf-flip-card-front {
        line-height: calc(0.1 * var(--bcf-grow-flip-container));
    }
    .bcf-flip-card-back {
        line-height: calc(0.09 * var(--bcf-grow-flip-container));
    }
    .bcf-flip-card-container .card-text {
        font-size: calc(0.07 * var(--bcf-grow-flip-container));
    }
    .bcf-flip-card-back .card-body {
        padding: 1rem 1.5rem;
    }
    .list-group {
        font-size: calc(0.06 * var(--bcf-grow-flip-container));
        line-height: calc(0.075 * var(--bcf-grow-flip-container));
    }
    .bcf-cards-container .card-text {
        padding: calc(0.043 * var(--bcf-grow-flip-container));
    }
    .bcf-cards-container .card-title {
        padding-top: calc(0.065 * var(--bcf-grow-flip-container));
    }
}
@media screen and (max-width: 1500px){
    #recentArticle,
    #recentPaper,
    #recentPodcast {
        background-size: 80vw;
    }
    .section-title-text {
        line-height: normal !important; 
    }
    .section-title-text-large {
        line-height: normal !important; 
    }
}
@media screen and (min-width: 1401px) {
    .cohort-text {
        padding-left: 0px;
        padding-right: 0px;
    }
}
@media screen and (max-width: 1400px) {
    .section-subtitle-text {
        line-height: normal !important;
        padding-bottom: 10px;
    }
    .cohort .flex-container#cohort{
        flex-direction: column;
        padding-right: 0;
        padding-left: 0;
    }
    #quote-card {
        margin-top: 0;
        padding-right: var(--homepage-horizontal-padding);
        padding-left: var(--homepage-horizontal-padding);
        display: flex;
        align-items: center;
        justify-content: center;
        justify-self: center;
        align-self: center;
        width: 100%;
        
    }
    #quote.card {
        height: 100% !important;
    }
    .animation-helper {
        height: 100%;
    }
    .animation-div {
        height: 100%;
        width: 100%;
        height: 350px;
        max-width: 550px;
    }
    .background-box {
        height: 100%;
    }
    /* .cohort .flex-child#intro-right {
        padding-top: var(--homepage-vertical-padding);
    } */
    .card-text#quote-text {
        margin-top: 5%;
        font-size: 1.4rem;
    }
    .flex-child#card-left > img{
        width: 120px;
    }
    .flex-child#card-right > .card-title {
        font-size: 2rem;
    }
    .flex-child#card-right > .card-subtitle {
        font-size: 1.25rem;
    }
    #intro-right.flex-child {
        width: 100%;
        margin-right: auto;
    }
    
    .scroll-through#company-cards {
        gap: 20px;
        padding-left: var(--homepage-horizontal-padding);
        padding-right: var(--homepage-horizontal-padding);
    }
    
    #popup-modal > div.modal-body > p {
        font-size: 12px;
    }
    .team .subsection-title-text {
        font-size: var(--subsection-title-font-size);
    }
    .partners .subsection-title-text {
        /* font-size: var(--title-font-size); */
        margin-bottom: 0;
    }
    .partners > div > div:nth-child(1) {
        padding-bottom: 2%;
    }
    .team .card-img {
        height: clamp(18px, 38%, 85px);
        width: clamp(18px, 38%, 85px);
    }
    .team svg {
        height: 22px;
        width: 22px;
    }
}
@media screen and (max-width: 1374px) {
    .team-page .team-array {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .companies-page .companies-array {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}
@media screen and (max-width: 1249px) {
    .engage .card-body {
        padding: 1.5rem 1.5rem calc(1.5rem + 5px) 1.5rem;
    }
    .engage-cap {
        margin-top: 10px;
        padding-right: var(--homepage-horizontal-padding);
        padding-left: var(--homepage-horizontal-padding);
    }
}
@media screen and (max-width: 1200px) {
    .news-checkbox-stacks {
        flex-direction: column;
    }
    .vl {
        display: none;
    }
    .type-specific-stack {
        margin-top: 10px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
@media screen and (max-width: 1199px) {
    .interact-flex-group {
        flex-direction: column;
        gap: 25px;
        align-items: center;
    }
    .team-search-container {
        margin-left: 0;
        width: 100%;
    }
    #recentArticle > * > .card-title,
    #recentPaper > * > .card-title,
    #recentPodcast > * > .card-title {
        padding-top: 25px;
    }
}
@media screen and (max-width: 1049px) {
    .team-page .team-array {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .companies-page .companies-array {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}
@media screen and (max-width: 1020px) {
    .footer-wharton-logo{
        /* height: 5rem; */
        /* width: 90%; */
        /* margin-left: 0; */
    }
}
@media screen and (max-width: 999px) {
    .company {
        margin-top: 15px;
    }
    :root {
        --homepage-vertical-padding: 7.5vh;
        --homepage-horizontal-padding: 7.5vw;
    }
    .hero-main-container {
        /* text-align: center; */
        /* align-items: center; */
        /* padding-top: 20px; */
        padding: 1vh var(--homepage-horizontal-padding) 1vh var(--homepage-horizontal-padding);
        /* gap: clamp(1rem, 3vh, 3rem); */
        /* justify-content: end; */
    }
    .bcf-cards-container {
        padding: 0px;
    }
    .swiper-wrapper {
        padding: 60px 0px 30px 0px;
    }
    .information {
        padding-right: 0;
        padding-left: 0;
    }
    .apply-container {
        width: 100%;
        display: flex;
        justify-content: center;
        position: relative;
    }
    .hero .background-image {
        width: 100%;
        background-position: center;
    }
    .hero-description { 
        /* align-self: center; */
        width: 70%;
        /* font-weight: 700; */
        /* -webkit-text-stroke-width: 0.25px; */
        /* -webkit-text-stroke-color: black; */
    } 
    /* .hero-description {
        font-size: clamp(1.25rem, 3.5vw, 1.5rem);
        padding-left: 2vh;
    } */
    .hero-logo-image {
        max-height: 45%;
    }
    .team .cypher-team {
        margin-top: 2vh;
    }
    .team-page .carousel .card-body {
        flex-direction: column;
    }
    .team-page .carousel {
        --carousel-text-padding: 30px;
    }
    .team-page .carousel .card-text {
        padding: var(--carousel-text-padding) var(--carousel-text-padding) 0px var(--carousel-text-padding);
        text-align: center;
        font-size: clamp(1.5rem, 4.0vw, 2.0rem);
    }
    .team-page .carousel .card-body {
        height: 400px;
    }
    .team-page .carousel .card-img {
        width: 27.5%;
        min-width: 250px;
    }
    .hero .section-title-text {
        font-size: clamp(2.5rem, 8vw, 5rem);
    }
    .engage {
        --engage-button-height: 3.0rem;
    }
    .engage .card-title {
        font-size: 2.15rem;
    }
    #recentArticle > * > .card-title,
    #recentPaper > * > .card-title,
    #recentPodcast > * > .card-title {
        padding-top: 20px;
    }
}
@media screen and (max-width: 991px) {
    #navbar {
        background-color:#000;
    }
}
@media screen and (max-width: 915px) {
    .engage .card-title {
        font-size: 2.05rem;
    }
}
@media screen and (min-width: 875px) {
    .engage .join-us.card {
        max-width: 500px;
    }
    .engage .apply.card {
        max-width: 500px;
    }
}
@media screen and (max-width: 874px) {
    /* .engage .card {
        width: 100% !important;
    /* } */
    .engage .join-us.card {
        width: 100% !important;
    }
    .engage .apply.card {
        width: 100% !important;
    } 
    .apply-background {
        width: 100%;
        margin-right: var(--homepage-horizontal-padding);
        margin-left: var(--homepage-horizontal-padding);
        margin-bottom: 5vw;
    }
    .engage-cap {
        padding-right: var(--homepage-horizontal-padding) !important;
        padding-left: var(--homepage-horizontal-padding) !important;
    }
    .engage {
        display: flex;
        flex-direction: column;
        justify-content: left;
    }
    .engage .card-text {
        font-size: clamp(1.25rem, 2.5vw, 1.5rem);
        padding: 15px 5%;
    }
    .engage {
        --engage-button-height: 3rem;
    }
    .footer-stack-container {
        display: block;
    }
    .footer-left {
        display: block;
        font-size: 0.85rem;
    }
    .footer-links {
        font-size: 0.85rem;
    }
    .footer-middle-left,
    .footer-middle {
        padding-bottom: 1rem;
    }

    .footer-right {
        padding-bottom: 0rem;
    }
    
}
@media screen and (max-width: 749px) {
    .interact-panel {
        padding: 2rem;
    }
    .team-page .carousel {
        --carousel-text-padding: 20px;
    }
    .team-page .carousel .card-body {
        height: 350px;
    }
    .team-page .carousel .carousel-text-container {
        padding: 0px var(--carousel-text-padding) var(--carousel-text-padding) 0px;
    }
    .team-page .carousel .card-text {
        padding: var(--carousel-text-padding) var(--carousel-text-padding) 0px var(--carousel-text-padding);
        font-size: clamp(1.45rem, 3.5vw, 2rem);
    }
    .team-page .carousel .card-title {
        font-size: max(4vw, 1.25rem);
    }
    .team-page .carousel .card-subtitle {
        font-size: max(3vw, 1rem);
    }
    .team-page .carousel .card-img {
        width: 38%;
        min-width: 200px;
    }
    .secondary-page-header {
        justify-content: center;
        padding-right: 30px;
        padding-left: 30px;
    }
    .secondary-page-header .section-title-text {
        font-size: clamp(2rem, 10vw, 3.5rem);
    }
    .secondary-page-header > img {
        width: clamp(2rem, 12.5vw, 4rem);
    }
    .hero-description {
        width: 80%;
        font-size: clamp(1.25rem, 3.5vw, 1.5rem);
    }
    .section-subtitle-text {
        font-size: clamp(1.25rem, 3.5vw, 1.5rem);
    }
    /* #recentArticle, 
    #recentPaper,
    #recentPodcast {
        margin-right: 20px;
        margin-left: 20px
    } */
    .team .scroll-through::-webkit-scrollbar {
        display: none;
    }
    .team .scroll-through, .news .scroll-through {
        scrollbar-width: none !important;
        -ms-overflow-style: none;
    }
    .team .scroll-cushion, .news .scroll-cushion {
        height: 5px !important;
    }
    .team .scroll-through {
        margin-bottom: 4vh;
    }
    .team {
        padding-bottom: 5vh;
    }
    .search-output {
        margin-bottom: 50px;
    }
}
@media screen and (max-width: 709px) {
    .map {
        padding-left: 20px;
        padding-right: 20px;
    }
    /* #recentArticle,
    #recentPaper,
    #recentPodcast {
        background-size: 80vw;
    } */
    #recentArticle > * > .card-title,
    #recentPaper > * > .card-title,
    #recentPodcast > * > .card-title {
        font-size: 20px;
    }

    #recentArticle > * > .card-subtitle {
        font-size: 13px;
    }
    
    .team-page .team-array {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .companies-page .companies-array {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}
@media screen and (max-width: 650px) {
    .card-body#quote-body {
        margin-left: 4%;
        margin-right: 4%;
    }
    .card-text#quote-text {
        margin-top: 5%;
        font-size: 1.4rem;
    }
    .flex-child#card-left > img{
        width: 17.5vw;
    }
    .flex-child#card-right > .card-title {
        font-size: clamp(1.25rem,4.5vw,2rem);
    }
    .flex-child#card-right > .card-subtitle {
        font-size: clamp(1rem,3vw,1.25rem);
    }
    .footer-stack {
        display: block;
    }
    #recentArticle,
    #recentPaper,
    #recentPodcast {
        background: url("../assets/images/backgrounds/news\ background.png") bottom left no-repeat;
        /* background-size: 300px; */
    }
}
@media screen and (max-width: 580px) {
    .expertise-pill-checkbox + label {
        font-size: 0.85em;
    }

    /* #recentPaper,
    #recentArticle,
    #recentPodcast {
        margin-left: 3vw;
        margin-right: 3vw;
    }     */
}
@media screen and (max-width: 550px) {
    .hero-logo-image {
        max-height: 38% !important;
    }
    .hero-main-container {
        justify-content: start;
        padding-top: 15vh;
    }
    .hero .engage-buttons {
        flex-direction: column;
    }
    .twitter-tweet {
        width: 300px !important;

    }
    .footer-wharton-logo{
        /* height: 5rem; */
        width: 70%;
        margin-left: 0;
    }
    .news-description,
    .panel-description {
        font-size: 20px;
    }
    .footer-penn-logo {
        /* height: 5rem; */
        
        width: 70%;        
    }
}
@media screen and (max-width: 500px) {
    :root {
        --homepage-vertical-padding: max(6vh, 40px);
        --homepage-horizontal-padding: 5vw;
    }
    .news-header {
        width: 90vw;
    }
    .cohort {
        padding-bottom: 0;
    }

    .company {
        margin-top: 10px;
    }
    /* Company Cards */
    .company-card {
        width: 175px;
        height: 75px;
    }
    .company-card .card-body {
        padding: 4px;
        width: 100%;
    }
    #company-info {
        margin-left: 0px;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
    }
    .flex-child#company-logo > img{
        width:50px;
    }
    .flex-container#card-info {
        display: block;
    }
    .flex-child#company-logo {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        vertical-align: middle;
        margin-left: 5px;
        margin-right: 10px;
        margin-top: 7px;
    }
    .flex-child#company-info {
        padding-top: 5px;
    }
    .flex-child#company-info .card-title{
        font-size: 13px; /* Card should not resize*/
    }
    #card-info {
        width: 40px;
    }
    #tags {
        width: auto;
        float: left;
    }
    /* #company-links {
       display: none;
    } */
    
    .team .scroll-through {
        scrollbar-width: none !important;
    }
    .secondary-page-wrapper {
        padding: 20px;
    }
    .team-page .person-description {
        font-size: 1rem;
    }
    /* .hero-main-container {
        padding: 15vh 8vw 5vh 8vw;
    } */
    .team-page .carousel {
        --carousel-text-padding: 10px;
    }
    .team-page .carousel .card-body {
        height: 300px
    }
    .team-page .carousel .carousel-text-container {
        padding: 0px 15px 15px 0px;
    }
    .team-page .carousel .card-text {
        font-size: clamp(1.0rem, 5vw, 1.65rem);
    }
    .team-page .carousel .card-img {
        min-width: 150px;
    }
    .carousel .carousel-control-prev, .carousel .carousel-control-next {
        width: 20px;
    }
    .team-page .carousel .carousel-indicators {
        margin-bottom: 0.25rem;
    }
    .team .scroll-through {
        gap: 40px;
        margin-bottom: 2.5vh;
    }
    .engage .card-title {
        font-size: 2rem;
    }
    .engage-cap {
        padding-right: var(--homepage-horizontal-padding) !important;
        padding-left: var(--homepage-horizontal-padding) !important;
    }
    .apply-background {
        margin-right: var(--homepage-horizontal-padding);
        margin-left: var(--homepage-horizontal-padding);
    }
    .team-text {
        padding-right: var(--homepage-horizontal-padding);
        padding-left: var(--homepage-horizontal-padding);
    }
    .team .subsection-title-text {
        padding-right: var(--homepage-horizontal-padding);
    }
    .secondary-page-header .section-title-text {
        font-size: clamp(1.5rem, 7vw, 2.5rem);
    }
    .scroll-through {
        padding-right: var(--homepage-horizontal-padding);
        padding-left: var(--homepage-horizontal-padding);
    }
    /* .information {
        padding-top: 10vh;
    } */
    .information-text {
        padding-left: var(--homepage-horizontal-padding);
    }
    .cohort .flex-container#cohort {
        padding-right: var(--homepage-horizontal-padding);
        padding-left: var(--homepage-horizontal-padding);
    }
    .cohort-text {
        padding-left: 0;
        padding-right: 0;
    }
    #quote-card {
        padding-right: 0px;
        padding-left: 0px;
    }
    .animation-div {
        height: 300px;
    }
    .background-box {
        margin-top: 7.5px;
        margin-left: 7.5px;
    }
    .team .cypher-team {
        margin-top: 1vh;
    }
    .card-text#quote-text {
        font-size: clamp(1.1rem, 4.5vw, 1.225rem)
    }

    .navbar-brand {
        margin-left: 0.5rem !important;
    }
    .hero .black-button {
        font-size: 1rem;
    }
    .hero .gradient-button {
        font-size: 1rem;
    }
    .hero button {
        width: 200px;
        height: 40px !important;
    }
    .hero .engage-buttons {
        gap: 10px;
    }
    .hero-main-container {
        justify-content: start;
        padding-top: 15vh;
    }
}
@media screen and (max-width: 350px) {
    .hero-main-container {
        justify-content: start;
        padding-top: 15vh;
    }
    .hero-logo-image {
        max-height: 35% !important;
    }
    .team .scroll-through {
        gap: 35px;
    }
    .engage .card-title {
        font-size: 1.9rem;
    }
    .background-box {
        margin-top: 5px;
        margin-left: 5px;
    }
    .flex-child#card-right > .card-subtitle {
        font-size: 0.85rem;
    }
    .flex-child#card-right > .card-title {
        font-size: 1rem;
    }
    .marquee-section .marquee .group  {
        font-size: 0.9rem;
    }
}
@media screen and (max-width: 300px) {
    .hero-logos {
        flex-direction: column;
        height: 15vh !important;
    }
    .hero-logo-image {
        max-height: 30% !important;
    }
    .team .scroll-through {
        gap: 30px;
    }
    
    .section-title-text {
        font-size: 1.8rem;
    }
    .section-title-text-large {
        font-size: 1.8rem;
    }
    .section-subtitle-text {
        font-size: clamp(1.25rem, 3.5vw, 1.5rem);
    }
    .information {
        --flip-card-height: 305px;
        --flip-card-width: 220px;
    }
    .swiper-slide {
        width: 220px !important;
        height: 305px !important;
    }
    .bcf-flip-card-container {
        width: calc(var(--flip-card-width) + 5px) !important;
        height: 305px !important;
    }
    .bcf-flip-card-front, .bcf-flip-card-back {
        padding: 2px;
        line-height: 1.375rem;
    }
    .bcf-flip-card-back {
        padding: 2px 0px 2px 0px;
    }
    .bcf-flip-card-container .card-title {
        font-size: 1rem;
    }
    .bcf-flip-card-container .card-text {
        font-size: 1rem;
    }
    .bcf-flip-card-back .card-body {
        padding: 1rem 0.5rem;
    }
    .list-group {
        font-size: 0.8125rem;
    }
    .team-page .carousel .card-img {
        min-width: 125px;
    }
    .team-page .carousel .card-subtitle {
        font-size: 0.85rem;
    }
    .team-page .carousel .card-title {
        font-size: 1.15rem;
    }
    .flex-child#card-right > .card-subtitle {
        font-size: 0.75rem;
    }
    .card-text#quote-text {
        font-size: 1rem;
    }
}
@media screen and (min-width: 500px) {
    .secondary-page-wrapper {
        padding: 0 32px 32px 32px;
    }
}
@media screen and (max-width: 450px) {
    
}
@media screen and (max-height: 767px) {
    .hero-logos {
        height: max(10vh, 75px);
    }
    .hero-main-container {
        height: max(calc(90vh - var(--penn-branding-height)), calc(715px - var(--marquee-height)));
    }
    .hero .background-image {
        height: max(calc(100vh - var(--penn-branding-height) - var(--marquee-height)),715px);
    }
}
@media(hover: hover) and (pointer: fine) {
    .apply-button-container:hover .white-button, .engage .button-container:hover .gradient-button {
        top: 5px;
        left: 5px;
        box-shadow: 0px 0px;
    }
    .black-button:hover {
        font-size: 1.25rem;
        background-size: 100% 100%;
        color: black;
    }
    .navbar .gradient-button:hover, .hero .gradient-button:hover {
        font-size: 1.25rem;
        background-size: 0% 100%;
        color: black;
    }
    .nav-link:hover, .navbar-links-and-buttons a:hover{
        color: rgba(256,256,256,0.6) !important;
    }
    .company-container:hover .company-card {
        top: 5px;
        left: 5px;
        box-shadow: 0px 0px;
        cursor: pointer;
    }
    .newspiece-container:hover #papers, .newspiece-container:hover #visuals {
        cursor: pointer;
        top: 5px;
        left: 5px;
        box-shadow: 0px 0px;
    }
    .footer-right > div.hstack.gap-1 > a:hover,
    .footer-links a:hover {
        text-decoration: none;
        color: white;
        opacity: 1;
    }
    .newspiece-container:hover .card#articles{
        top: 5px;
        left: 5px;
        box-shadow: 0px 0px;
        cursor: pointer;
    }
    .front:hover {
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-iteration-count: 1;
                animation-iteration-count: 1;
        -webkit-animation-name: frontTransition;
                animation-name: frontTransition;
        r: 10;
        fill: #000000;
        cursor: pointer;
    }
}
/* #endregion */

/* #region Gradient Settings */
@media screen and (min-width: 1901px) {
    .team .subsection-title-text {
        background-image: linear-gradient(to right, var(--cypher-color-2) 40%, var(--cypher-color-1) 45%, var(--cypher-color-1) 50%, #ededed 50%, #ededed 100%);
    }
}
@media screen and (max-width: 1900px) {
    .team .subsection-title-text {
        background-image: linear-gradient(to right, var(--cypher-color-2) 39%, var(--cypher-color-1) 44%, var(--cypher-color-1) 50%, #ededed 50%, #ededed 100%);
    }
}
@media screen and (max-width: 1800px) {
    .team .subsection-title-text {
        background-image: linear-gradient(to right, var(--cypher-color-2) 39%, var(--cypher-color-1) 46%, var(--cypher-color-1) 50%, #ededed 50%, #ededed 100%);
    }
}
@media screen and (max-width: 1700px) {
    .team .subsection-title-text {
        background-image: linear-gradient(to right, var(--cypher-color-2) 39%, var(--cypher-color-1) 45%, var(--cypher-color-1) 50%, #ededed 50%, #ededed 100%);
    }
}
@media screen and (max-width: 1600px) {
    .team .subsection-title-text {
        background-image: linear-gradient(to right, var(--cypher-color-2) 38%, var(--cypher-color-1) 44%, var(--cypher-color-1) 50%, #ededed 50%, #ededed 100%);
    }
}
@media screen and (max-width: 1500px) {
    .team .subsection-title-text {
        background-image: linear-gradient(to right, var(--cypher-color-2) 38%, var(--cypher-color-1) 45%, var(--cypher-color-1) 50%, #ededed 50%, #ededed 100%);
    }
}
@media screen and (max-width: 1400px) {
    .team .subsection-title-text {
        background-image: linear-gradient(to right, var(--cypher-color-2) 37%, var(--cypher-color-1) 44%, var(--cypher-color-1) 50%, #ededed 50%, #ededed 100%);
    }
}
@media screen and (max-width: 1300px) {
    .team .subsection-title-text {
        background-image: linear-gradient(to right, var(--cypher-color-2) 37%, var(--cypher-color-1) 43%, var(--cypher-color-1) 50%, #ededed 50%, #ededed 100%);
    }
}
@media screen and (max-width: 1200px) {
    .team .subsection-title-text {
        background-image: linear-gradient(to right, var(--cypher-color-2) 36%, var(--cypher-color-1) 43%, var(--cypher-color-1) 50%, #ededed 50%, #ededed 100%);
    }
}
@media screen and (max-width: 1100px) {
    .team .subsection-title-text {
        background-image: linear-gradient(to right, var(--cypher-color-2) 34%, var(--cypher-color-1) 43%, var(--cypher-color-1) 50%, #ededed 50%, #ededed 100%);
    }
}
@media screen and (max-width: 1000px) {
    .team .subsection-title-text {
        background-image: linear-gradient(to right, var(--cypher-color-2) 35%, var(--cypher-color-1) 42%, var(--cypher-color-1) 50%, #ededed 50%, #ededed 100%);
    }
}
@media screen and (max-width: 900px) {
    .team .subsection-title-text {
        background-image: linear-gradient(to right, var(--cypher-color-2) 33%, var(--cypher-color-1) 43%, var(--cypher-color-1) 50%, #ededed 50%, #ededed 100%);
    }
}
@media screen and (max-width: 800px) {
    .team .subsection-title-text {
        background-image: linear-gradient(to right, var(--cypher-color-2) 33%, var(--cypher-color-1) 42%, var(--cypher-color-1) 50%, #ededed 50%, #ededed 100%);
    }
}
@media screen and (max-width: 700px) {
    .team .subsection-title-text {
        background-image: linear-gradient(to right, var(--cypher-color-2) 32%, var(--cypher-color-1) 43%, var(--cypher-color-1) 50%, #ededed 50%, #ededed 100%);
    }
}
@media screen and (max-width: 600px) {
    .team .subsection-title-text {
        background-image: linear-gradient(to right, var(--cypher-color-2) 33%, var(--cypher-color-1) 43%, var(--cypher-color-1) 50%, #ededed 50%, #ededed 100%);
    }
}
@media screen and (max-width: 500px) {
    .team .subsection-title-text {
       background-image: linear-gradient(to right, var(--cypher-color-2) 30%, var(--cypher-color-1) 40%, var(--cypher-color-1) 50%, #ededed 50%, #ededed 100%);
    }
}
@media screen and (max-width: 400px) {
    .team .subsection-title-text {
       background-image: linear-gradient(to right, var(--cypher-color-2) 25%, var(--cypher-color-1) 40%, var(--cypher-color-1) 50%, #ededed 50%, #ededed 100%);
    }
}
/* #endregion */

@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    .team #khushi_shelat {
        margin-right: calc(5px + var(--homepage-horizontal-padding));
    }
    .team #paul_veradittakit {
        margin-left: var(--homepage-horizontal-padding);
    }
    .news .newspiece:last-of-type {
        margin-right: var(--homepage-horizontal-padding);
    }
    .cohort .vstack:last-of-type > div {
        margin-right: calc(5px + var(--homepage-horizontal-padding));
    }
}}
